import React, { useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { store } from '../../store/redux';

const SSOAuthorization = () => {
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const state = queryParams.get('state');
        const client_id = queryParams.get('client_id');
        const redirect_uri = queryParams.get('redirect_uri');
        const response_type = queryParams.get('response_type');
        const scope = queryParams.get('scope');
        const jwtToken = store.getState()._session.access_token;
        console.log('state', state, 'client_id', client_id, 'redirect_uri', redirect_uri, 'response_type', response_type, 'scope', scope, 'jwtToken', jwtToken);


        if (state && redirect_uri && jwtToken) {
            const redirectUri = `${redirect_uri}?code=${'test123'}&state=${state}&token=${jwtToken}`; // Redirect upon successful authorization.
            window.location.href = redirectUri;
        }
    }, [location.search]);

    return <div>Authorizing...</div>;
};

export default SSOAuthorization;
