import Radar from '../assets/images/svg/Sidebar/Radar.svg';
import Chart from '../assets/images/svg/Sidebar/Chart.svg';
import MyPlaylist from '../assets/images/svg/Sidebar/MyPlaylist.svg';
import MyVideos from '../assets/images/svg/Sidebar/MyVideos.svg';
import UploadVideo from '../assets/images/svg/Sidebar/UploadVideo.svg';
import GoLive from '../assets/images/svg/Sidebar/GoLive.svg';
import WatchLater from '../assets/images/svg/Sidebar/WatchLater.svg';
import AdManager from '../assets/images/svg/Sidebar/AdManager.svg';
import Leaderboard from '../assets/images/svg/Sidebar/Leaderboard.svg';
import Settings from '../assets/images/svg/Sidebar/Settings.svg';
import { spaces } from '../utils/images';

// import { radarIcon } from '../assets/svg';

const SidebarElements = [
    {
        name: 'Radar',
        icon: Radar,
        // activeIcon: RadarActive,
        path: '/radar',
        active: true
    },
    {
        name: 'Chart',
        icon: Chart,
        path: '/charts',
        active: true
    },
    {
        name: 'My Playlist',
        icon: MyPlaylist,
        path: '/my-playlist',
        active: true
    },
    {
        name: 'My Videos',
        icon: MyVideos,
        path: '/my-videos',
        active: true
    },
    {
        name: 'Upload Video',
        icon: UploadVideo,
        path: '/upload',
        active: true
    },
    {
        name: 'Go Live',
        icon: GoLive,
        path: '/video/live-streaming',
        active: true
    },
    {
        name: 'Watch Later',
        icon: WatchLater,
        path: '/watch-later',
        active: true
    },
    {
        name: 'Ad Manager',
        icon: AdManager,
        path: '/advertisement',
        active: true
    },
    {
        name: 'Leaderboard',
        icon: Leaderboard,
        path: '/leaderboard',
        active: true
    },
    {
        name: 'Settings',
        icon: Settings,
        path: '/settings',
        active: true
    },
    {
        name: 'circle space',
        icon: spaces,
        path: '/circle-space',
        active: true
    },
]

export default SidebarElements;